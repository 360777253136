@import url("https://fonts.googleapis.com/css2?family=Manrope&family=Roboto&display=swap");
body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  letter-spacing: 0.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #000;
}
body::-webkit-scrollbar {
  width: 0.5em;
}
body::-webkit-scrollbar-thumb {
  background-color: #a64af7;
}

.roboto {
  font-family: "Roboto" sans-serif;
}

button {
  margin: 5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
  min-width: 130px;
  height: 45px;
  border: none;
  display: inline-block;
  border-radius: 6px;
  color: white;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 100;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 2px;
}

button:before,
button:after {
  content: " ";
  position: absolute;
  border-radius: 6px;
}

button:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #a64af7;
  background: -moz-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #a64af7),
    color-stop(100%, #1daefd)
  );
  background: -webkit-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -o-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -ms-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: linear-gradient(to right, #a64af7 0%, #1daefd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#503b9a', endColorstr='#269cc1',GradientType=1 );
}

button:after {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: black;
  z-index: -1;
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

button:hover:after {
  opacity: 0;
}

.subText {
  font-size: 22px;
  color: #a64af7;
}
.colorWhite {
  color: white;
}

.NFTCard {
  background: linear-gradient(180deg, #17063c 0%, #1c0120 100%);
  height: auto;
  width: 250px;
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
  align-self: top;
}
.NFTCard > div {
  border: 1px solid #a64af7;
  border-radius: 10px;
  height: 550px;
  width: 250px;
}
.NFTCard > div > h3 {
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  padding: 10px;
  letter-spacing: normal;
}
.NFTCard > div > p {
  text-align: justify;
  padding: 10px;
  letter-spacing: 0rem;
  color: #959595;
  font-weight: 600;
  font-size: 15px;
}

.characterMiddle {
  height: 220px;
  position: absolute;
  z-index: 1;
}

.characterLeft {
  height: 200px;
  position: absolute;
  margin-left: 50px;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.characterRight {
  height: 200px;
  margin-left: -50px;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.characterContainer {
  margin-left: -25px;
}

footer {
  min-height: 300px;
  text-align: center;
  letter-spacing: 0em;
}

.footerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "flogo flogo flogo flogo flogo flogo"
    "fleft fleft flocation flocation fright fright"
    "fcopy fcopy fsocial fsocial fterms fterms";
  justify-items: center;
  margin-bottom: 50px;
}

.footerLogo {
  grid-area: flogo;
  margin: 30px;
}
.footerLogo > img {
  height: 60px;
}
.footerLeft {
  grid-area: fleft;
  width: 300px;
  text-align: left;
}
.footerLocation {
  grid-area: flocation;
  margin-bottom: 40px;
}
.footerLocation > h3 {
  margin-bottom: -10px;
}
.footerRight {
  grid-area: fright;
  display: flex;
  flex-direction: row;
}
.footerRight > .leftCol {
  margin-right: 15px;
  text-align: left;
}
.footerRight > .rightCol {
  margin-left: 25px;
  text-align: left;
}
.footerCopyright {
  grid-area: fcopy;
  font-size: 13px;
  text-align: left;
}
.footerSocial {
  grid-area: fsocial;
}

.footerSocial > img {
  margin: 10px;
}

.footerTerms {
  grid-area: fterms;
  font-size: 13px;
  display: flex;
  flex-direction: row;
}
.footerTerms > .leftCol {
  flex: auto;
  margin-left: 10px;
  margin-right: 10px;
}
.footerTerms > .rightCol {
  flex: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.RoadMapCard {
  background: linear-gradient(180deg, #17063c 0%, #1c0120 100%);
  height: auto;
  width: 300px;
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
  align-self: top;
  letter-spacing: 0em;
}
.RoadMapCard > div {
  border: 1px solid #a64af7;
  border-radius: 10px;
  height: 300px;
  width: 300px;
  text-align: left;
}
.RoadMapCard > div > h3 {
  margin: 10px;
  font-size: 25px;
}
.RoadMapCard > div > .task {
  margin-left: 10px;
}
.RoadMapCard > div > .task {
  margin-left: 10px;
}
.taskStat {
  position: relative;
  top: 8px;
  height: 30px;
}
.status {
  height: 20px;
  font-size: 48px;
  font-weight: 800;
  /* Abs positioning makes it not take up vert space */
  position: relative;
  left: 230px;
  top: 200px;
  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  transform: rotate(-90deg);
  color: #17063c;
  text-shadow: -1px 1px 0 #a64af7, 1px 1px 0 #a64af7, 1px -1px 0 #a64af7,
    -1px -1px 0 #a64af7;
}

.FeatureCard {
  background: linear-gradient(180deg, #17063c 0%, #1c0120 100%);
  height: auto;
  width: 300px;
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
  align-self: top;
  text-align: center;
  letter-spacing: 0em;
}
.FeatureCard > img {
  height: 150px;
  margin-top: 10px;
}
.FeatureCard > p {
  color: #aaa;
  font-size: 15px;
}

.connectButton {
  width: 300px;
  font-size: 20px;
  font-weight: bold;
}
.connectContainer {
  position: relative;
  text-align: center;
  margin-top: 300px;
  letter-spacing: 0em;
}
.connectContainer >span {
  color: #aaa;
}

.progressBar{
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 100px;
  width: calc(100% - 200px);
  height: 20px;
  background-color: #a64af755;
  border-radius: 10px;
  overflow: hidden;
}
.progressBar > div{
  width: calc(50%);
  height: 20px;
  background-color: #a64af7;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: -webkit-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -o-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -ms-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: linear-gradient(to right, #a64af7 0%, #1daefd 100%);
}
.progressContainer{
  position: absolute;
  height: auto;
  width: calc(100% - 400px);
  margin: 200px;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-top: 10px;
  border-radius: 10px;
  z-index: 100;
  
}
.progressContainer:before{
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #a64af7;
  background: -moz-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #a64af7),
    color-stop(100%, #1daefd)
  );
  background: -webkit-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -o-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: -ms-linear-gradient(left, #a64af7 0%, #1daefd 100%);
  background: linear-gradient(to right, #a64af7 0%, #1daefd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#503b9a', endColorstr='#269cc1',GradientType=1 );

}
.progressContainer:after {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: black;
  z-index: -1;
  opacity: 1;
}
.progressContainer:before,
.progressContainer:after {
  content: " ";
  position: absolute;
  border-radius: 6px;
}
.progressContainer > h3 {
  margin-left: 20px;
  margin-top: 0px;
  font-size: 20px;
  /* background: -webkit-linear-gradient(#a64af7, #1daefd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.progressContainer > label {
  letter-spacing: normal;
  font-weight: bolder;
  margin-left: 20px;
  font-size: 20px;
  color: #aaa;
  /* background: -webkit-linear-gradient(#a64af7, #1daefd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.web3StatusDisplay{
  letter-spacing: 0em;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #a64af766;
  padding:5px;
  padding-left:20px;
  padding-right:20px;
  border-radius: 20px;
}
.viewSeedBody{
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #a64af733 -30%, #090a0f 100%);

}
.seedPageLogo{
  position: absolute;
  left: calc(50% - 30px);
  top:10px;
  height:60px;

}
.disconnectButton{
  background-color: black;
  height: 23px;
  padding: 3px;
  padding-left: 3px;
  padding-right: 3px;
  letter-spacing: normal;
  width: 50px;
  border: red 1px solid;
  margin-left: 10px;
}
.disconnectButton:after{
  background: #00000000;
  display: none;
}
.disconnectButton:before{
  background: #00000000;
  display: none;
}
@media screen and (max-width: 1020px) {
  .progressContainer{
    position: absolute;
    height: auto;
    width: calc(100% - 10px);
    margin: 5px;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 10px;
    border-radius: 10px;
    z-index: 100;
    
  }
}

@media screen and (max-width: 800px) {

}
@media screen and (max-width: 600px) {
  footer {
    min-height: 300px;
    text-align: center;
    letter-spacing: 0em;
  }
  
  .footerGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "flogo "
      "flocation"
      "fleft"
      "fright"
      "fsocial"
      "fcopy" 
      "fterms";
    justify-items: center;
    margin-bottom: 50px;
  }
  
  .footerLogo {
    grid-area: flogo;
    margin: 30px;
  }
  .footerLogo > img {
    height: 60px;
  }
  .footerLeft {
    grid-area: fleft;
    text-align: center;
    margin: 10px;
  }
  .footerLocation {
    grid-area: flocation;
    margin-bottom: 40px;
  }
  .footerLocation > h3 {
    margin-bottom: -10px;
  }
  .footerRight {
    grid-area: fright;
    display: flex;
    flex-direction: row;
    align-content: stretch;
  }
  .footerRight > .leftCol {
    margin-right: 15px;
    text-align: left;
    flex: auto;
  }
  .footerRight > .rightCol {
    margin-left: 25px;
    text-align: left;
    flex: auto;
  }
  .footerCopyright {
    grid-area: fcopy;
    font-size: 13px;
    text-align: left;
  }
  .footerSocial {
    grid-area: fsocial;
  }
  
  .footerSocial > img {
    margin: 3px;
  }
  
  .footerTerms {
    grid-area: fterms;
    font-size: 13px;
    display: flex;
    flex-direction: row;
  }
  .footerTerms > .leftCol {
    flex: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .footerTerms > .rightCol {
    flex: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
   /* View Seed Page */
  .connectButton {
    width: 300px;
  }
  .connectContainer {
    position: relative;
    text-align: center;
    margin-top: 300px;
    letter-spacing: 0em;
  }
  .connectContainer >span {
    color: #aaa;
  }
  
  .progressBar{
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 100px;
    width: calc(100% - 200px);
    height: 20px;
    background-color: #a64af755;
    border-radius: 10px;
    overflow: hidden;
  }
  .progressBar > div{
    width: calc(50%);
    height: 20px;
    background-color: #a64af7;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: -webkit-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: -o-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: -ms-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: linear-gradient(to right, #a64af7 0%, #1daefd 100%);
  }
  .progressContainer{
    position: absolute;
    height: auto;
    width: calc(100% - 10px);
    margin: 5px;
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 10px;
    border-radius: 10px;
    z-index: 100;
    
  }
  .progressContainer:before{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
    background: #a64af7;
    background: -moz-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, #a64af7),
      color-stop(100%, #1daefd)
    );
    background: -webkit-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: -o-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: -ms-linear-gradient(left, #a64af7 0%, #1daefd 100%);
    background: linear-gradient(to right, #a64af7 0%, #1daefd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#503b9a', endColorstr='#269cc1',GradientType=1 );
  
  }
  .progressContainer:after {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background-color: black;
    z-index: -1;
    opacity: 1;
  }
  .progressContainer:before,
  .progressContainer:after {
    content: " ";
    position: absolute;
    border-radius: 6px;
  }
  .progressContainer > h3 {
    margin-left: 20px;
    margin-top: 0px;
    font-size: 20px;
    /* background: -webkit-linear-gradient(#a64af7, #1daefd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  
  .progressContainer > label {
    letter-spacing: normal;
    font-weight: bolder;
    margin-left: 20px;
    font-size: 10px;
    color: #aaa;
    /* background: -webkit-linear-gradient(#a64af7, #1daefd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  
  .web3StatusDisplay{
    letter-spacing: 0em;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #a64af766;
    padding:5px;
    padding-left:20px;
    padding-right:20px;
    border-radius: 20px;
    font-size: 10px;
  }
  .viewSeedBody{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(ellipse at bottom, #a64af733 -30%, #090a0f 100%);
  
  }
  .seedPageLogo{
    position: absolute;
    left: calc(50% - 30px);
    top:10px;
    height:60px;
  
  }
}