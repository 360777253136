#stars {
  position: absolute;
  top:0;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1561px 496px #fff, 130px 172px #fff, 301px 1183px #fff,
    1515px 1414px #fff, 391px 1381px #fff, 1624px 40px #fff, 265px 317px #fff,
    866px 232px #fff, 1476px 355px #fff, 1893px 147px #fff, 1741px 908px #fff,
    1626px 1170px #fff, 1301px 821px #fff, 503px 137px #fff, 946px 1322px #fff,
    1725px 292px #fff, 1453px 1120px #fff, 147px 1359px #fff, 1082px 886px #fff,
    1906px 1023px #fff, 229px 181px #fff, 812px 1304px #fff, 454px 582px #fff,
    1677px 1594px #fff, 1755px 307px #fff, 1125px 177px #fff, 435px 1278px #fff,
    1816px 726px #fff, 416px 1963px #fff, 775px 1209px #fff, 1571px 651px #fff,
    1530px 1122px #fff, 1452px 1387px #fff, 554px 168px #fff, 641px 254px #fff,
    526px 778px #fff, 1015px 39px #fff, 530px 1840px #fff, 552px 325px #fff,
    28px 399px #fff, 768px 1008px #fff, 368px 1178px #fff, 596px 145px #fff,
    1964px 1201px #fff, 657px 536px #fff, 97px 530px #fff, 1542px 944px #fff,
    1074px 1855px #fff, 1746px 1534px #fff, 867px 731px #fff, 1659px 501px #fff,
    1220px 1813px #fff, 116px 1406px #fff, 1017px 1972px #fff, 438px 851px #fff,
    322px 98px #fff, 823px 1425px #fff, 1885px 586px #fff, 650px 908px #fff,
    1041px 333px #fff, 1256px 106px #fff, 1381px 1581px #fff, 1555px 1261px #fff,
    899px 1344px #fff, 1605px 5px #fff, 484px 359px #fff, 1733px 1129px #fff,
    151px 135px #fff, 709px 1709px #fff, 103px 963px #fff, 480px 522px #fff,
    1432px 1913px #fff, 645px 675px #fff, 1351px 931px #fff, 1376px 418px #fff,
    1571px 1341px #fff, 1332px 985px #fff, 189px 179px #fff, 110px 1085px #fff,
    1800px 593px #fff, 390px 672px #fff, 890px 614px #fff, 1745px 662px #fff,
    1479px 427px #fff, 1428px 507px #fff, 598px 1921px #fff, 1813px 366px #fff,
    1986px 1854px #fff, 1054px 153px #fff, 760px 223px #fff, 880px 909px #fff,
    384px 856px #fff, 500px 1475px #fff, 1574px 1959px #fff, 1479px 686px #fff,
    707px 557px #fff, 1618px 568px #fff, 484px 103px #fff, 1793px 1399px #fff,
    1874px 1820px #fff, 1074px 1694px #fff, 467px 341px #fff, 285px 620px #fff,
    1304px 850px #fff, 1321px 805px #fff, 818px 1758px #fff, 1450px 1609px #fff,
    44px 203px #fff, 614px 612px #fff, 288px 1593px #fff, 1944px 200px #fff,
    88px 1180px #fff, 1954px 214px #fff, 1372px 177px #fff, 729px 1824px #fff,
    885px 1996px #fff, 1821px 136px #fff, 426px 553px #fff, 626px 1090px #fff,
    771px 1157px #fff, 399px 1303px #fff, 1796px 932px #fff, 732px 151px #fff,
    1794px 1811px #fff, 466px 1585px #fff, 133px 1770px #fff, 856px 844px #fff,
    244px 1841px #fff, 474px 293px #fff, 1051px 1348px #fff, 787px 1775px #fff,
    318px 1506px #fff, 755px 1383px #fff, 123px 935px #fff, 752px 1737px #fff,
    1638px 957px #fff, 848px 1876px #fff, 1524px 768px #fff, 1267px 115px #fff,
    1416px 1845px #fff, 951px 1536px #fff, 1665px 1912px #fff, 1194px 502px #fff,
    684px 1365px #fff, 1902px 1145px #fff, 148px 1701px #fff, 904px 1709px #fff,
    445px 498px #fff, 1834px 627px #fff, 1941px 1975px #fff, 416px 666px #fff,
    231px 1695px #fff, 1709px 1032px #fff, 226px 1156px #fff, 196px 488px #fff,
    563px 860px #fff, 129px 1920px #fff, 1464px 1262px #fff, 2px 1887px #fff,
    438px 117px #fff, 1040px 1041px #fff, 892px 1555px #fff, 384px 1826px #fff,
    1289px 1878px #fff, 1924px 1269px #fff, 914px 420px #fff, 952px 836px #fff,
    1419px 1108px #fff, 1789px 704px #fff, 38px 808px #fff, 391px 847px #fff,
    455px 1818px #fff, 231px 174px #fff, 1566px 1315px #fff, 139px 969px #fff,
    733px 345px #fff, 1392px 1599px #fff, 775px 1318px #fff, 624px 762px #fff,
    1147px 1526px #fff, 1533px 1150px #fff, 1529px 1749px #fff,
    1890px 606px #fff, 1876px 603px #fff, 1270px 478px #fff, 1228px 1556px #fff,
    822px 719px #fff, 1424px 1345px #fff, 1578px 1517px #fff, 530px 1662px #fff,
    557px 1989px #fff, 301px 1782px #fff, 1614px 1468px #fff, 1069px 1655px #fff,
    1973px 929px #fff, 1511px 1385px #fff, 1243px 1575px #fff,
    1566px 1675px #fff, 767px 62px #fff, 813px 939px #fff, 1349px 680px #fff,
    1806px 534px #fff, 1543px 1726px #fff, 23px 948px #fff, 791px 1077px #fff,
    161px 1360px #fff, 45px 1941px #fff, 770px 1232px #fff, 753px 122px #fff,
    1727px 1757px #fff, 801px 1092px #fff, 432px 1054px #fff, 1525px 1559px #fff,
    1534px 839px #fff, 416px 1623px #fff, 473px 1417px #fff, 1656px 291px #fff,
    744px 1001px #fff, 1889px 1025px #fff, 1522px 1184px #fff,
    1273px 1195px #fff, 968px 979px #fff, 89px 1500px #fff, 673px 418px #fff,
    1911px 1037px #fff, 243px 19px #fff, 854px 878px #fff, 846px 1037px #fff,
    905px 352px #fff, 360px 351px #fff, 947px 1689px #fff, 1749px 1681px #fff,
    1737px 99px #fff, 119px 905px #fff, 648px 260px #fff, 88px 1051px #fff,
    1108px 618px #fff, 132px 1308px #fff, 1342px 1640px #fff, 433px 152px #fff,
    1923px 945px #fff, 234px 1454px #fff, 785px 473px #fff, 1232px 1574px #fff,
    471px 1053px #fff, 1148px 1881px #fff, 1357px 1498px #fff,
    1886px 1707px #fff, 507px 1028px #fff, 1467px 1923px #fff, 464px 599px #fff,
    1497px 1521px #fff, 786px 1882px #fff, 1472px 1133px #fff, 607px 332px #fff,
    728px 528px #fff, 586px 1941px #fff, 1684px 1913px #fff, 1212px 1716px #fff,
    1363px 1385px #fff, 455px 1165px #fff, 611px 701px #fff, 1878px 518px #fff,
    1290px 1262px #fff, 1724px 1749px #fff, 920px 1760px #fff, 99px 1204px #fff,
    1350px 663px #fff, 159px 575px #fff, 1309px 1013px #fff, 1935px 1269px #fff,
    638px 484px #fff, 537px 1350px #fff, 546px 1515px #fff, 567px 1178px #fff,
    1149px 1436px #fff, 1918px 830px #fff, 1815px 1103px #fff, 616px 1659px #fff,
    383px 484px #fff, 1252px 719px #fff, 1413px 1456px #fff, 654px 1133px #fff,
    704px 651px #fff, 52px 1493px #fff, 1726px 244px #fff, 1218px 1926px #fff,
    1878px 1198px #fff, 384px 1798px #fff, 1992px 875px #fff, 1863px 270px #fff,
    1451px 173px #fff, 1614px 501px #fff, 319px 1732px #fff, 149px 1981px #fff,
    362px 356px #fff, 1833px 980px #fff, 19px 738px #fff, 48px 154px #fff,
    1725px 1666px #fff, 470px 1393px #fff, 702px 1027px #fff, 1099px 1652px #fff,
    691px 1963px #fff, 362px 1538px #fff, 421px 549px #fff, 1054px 313px #fff,
    572px 2000px #fff, 520px 1998px #fff, 1475px 201px #fff, 1994px 1034px #fff,
    1163px 115px #fff, 944px 315px #fff, 453px 302px #fff, 1101px 7px #fff,
    19px 1415px #fff, 391px 1283px #fff, 1127px 1233px #fff, 1474px 58px #fff,
    1629px 1739px #fff, 1617px 1954px #fff, 1448px 856px #fff,
    1511px 1440px #fff, 53px 1395px #fff, 1152px 1522px #fff, 267px 1084px #fff,
    1534px 783px #fff, 49px 503px #fff, 1173px 1099px #fff, 1260px 639px #fff,
    104px 219px #fff, 1621px 1854px #fff, 1813px 1999px #fff, 888px 111px #fff,
    1801px 512px #fff, 54px 1807px #fff, 27px 397px #fff, 796px 477px #fff,
    208px 1894px #fff, 26px 89px #fff, 1782px 1415px #fff, 647px 1829px #fff,
    1074px 1579px #fff, 1741px 617px #fff, 905px 765px #fff, 139px 1016px #fff,
    1775px 1289px #fff, 974px 1284px #fff, 726px 898px #fff, 1256px 78px #fff,
    1292px 1131px #fff, 1434px 330px #fff, 71px 1428px #fff, 19px 949px #fff,
    1664px 1800px #fff, 1277px 518px #fff, 977px 830px #fff, 949px 795px #fff,
    586px 765px #fff, 1844px 1531px #fff, 1118px 950px #fff, 1424px 210px #fff,
    1508px 1139px #fff, 796px 1331px #fff, 630px 354px #fff, 1369px 778px #fff,
    1762px 1193px #fff, 1146px 933px #fff, 604px 221px #fff, 211px 201px #fff,
    746px 598px #fff, 1503px 330px #fff, 1627px 1332px #fff, 1297px 1823px #fff,
    71px 358px #fff, 926px 1072px #fff, 479px 1082px #fff, 1508px 959px #fff,
    1254px 1215px #fff, 1206px 1310px #fff, 77px 1213px #fff, 207px 119px #fff,
    1642px 972px #fff, 839px 679px #fff, 1161px 590px #fff, 617px 545px #fff,
    1016px 1810px #fff, 1893px 1672px #fff, 1208px 644px #fff, 652px 1081px #fff,
    702px 4px #fff, 460px 1259px #fff, 1682px 1962px #fff, 520px 339px #fff,
    1987px 1656px #fff, 1166px 1513px #fff, 1949px 1975px #fff, 127px 188px #fff,
    1068px 1437px #fff, 1816px 173px #fff, 693px 859px #fff, 1663px 1675px #fff,
    1828px 1840px #fff, 14px 635px #fff, 370px 52px #fff, 1880px 850px #fff,
    686px 1782px #fff, 1460px 1843px #fff, 985px 635px #fff, 1849px 539px #fff,
    1232px 1608px #fff, 1438px 1000px #fff, 1491px 302px #fff, 420px 881px #fff,
    584px 1889px #fff, 1508px 327px #fff, 1852px 1521px #fff, 1312px 723px #fff,
    1424px 1486px #fff, 323px 941px #fff, 911px 399px #fff, 79px 1518px #fff,
    1008px 580px #fff, 1347px 769px #fff, 1733px 1170px #fff, 69px 802px #fff,
    1572px 1827px #fff, 952px 238px #fff, 54px 627px #fff, 1043px 1145px #fff,
    884px 325px #fff, 542px 514px #fff, 847px 859px #fff, 1585px 1004px #fff,
    567px 1809px #fff, 1331px 1494px #fff, 1182px 14px #fff, 683px 1416px #fff,
    354px 1462px #fff, 521px 347px #fff, 350px 1511px #fff, 356px 1154px #fff,
    1021px 1103px #fff, 885px 905px #fff, 1380px 513px #fff, 91px 1258px #fff,
    1563px 1785px #fff, 1598px 1961px #fff, 1362px 480px #fff, 391px 1606px #fff,
    127px 1685px #fff, 718px 1964px #fff, 327px 884px #fff, 13px 869px #fff,
    1395px 885px #fff, 713px 714px #fff, 1405px 10px #fff, 1297px 1002px #fff,
    1957px 1088px #fff, 1897px 1707px #fff, 1069px 537px #fff, 1465px 697px #fff,
    1472px 494px #fff, 1603px 311px #fff, 602px 1209px #fff, 34px 1218px #fff,
    1390px 794px #fff, 841px 1392px #fff, 2000px 466px #fff, 1955px 93px #fff,
    1199px 58px #fff, 961px 895px #fff, 689px 1127px #fff, 1270px 531px #fff,
    171px 1613px #fff, 1097px 42px #fff, 857px 1205px #fff, 119px 502px #fff,
    582px 1435px #fff, 1701px 994px #fff, 487px 1473px #fff, 477px 19px #fff,
    1723px 435px #fff, 1488px 1958px #fff, 1869px 1585px #fff, 50px 1320px #fff,
    1916px 1593px #fff, 104px 1679px #fff, 810px 134px #fff, 1648px 1409px #fff,
    441px 367px #fff, 174px 1217px #fff, 1677px 416px #fff, 794px 881px #fff,
    1513px 274px #fff, 1801px 413px #fff, 501px 523px #fff, 1844px 1496px #fff,
    1560px 377px #fff, 626px 383px #fff, 502px 196px #fff, 1045px 1637px #fff,
    1114px 1406px #fff, 513px 686px #fff, 307px 1574px #fff, 789px 610px #fff,
    1740px 237px #fff, 193px 1212px #fff, 809px 137px #fff, 878px 406px #fff,
    1133px 811px #fff, 1633px 884px #fff, 162px 1391px #fff, 88px 1296px #fff,
    1306px 333px #fff, 495px 635px #fff, 1931px 510px #fff, 1557px 1787px #fff,
    1868px 1253px #fff, 37px 1551px #fff, 45px 505px #fff, 621px 1657px #fff,
    536px 582px #fff, 1810px 144px #fff, 834px 509px #fff, 698px 398px #fff,
    1736px 524px #fff, 1424px 1990px #fff, 143px 1178px #fff, 226px 926px #fff,
    1584px 201px #fff, 1329px 1713px #fff, 1480px 1809px #fff, 115px 1947px #fff,
    563px 450px #fff, 841px 1964px #fff, 1882px 1883px #fff, 1917px 322px #fff,
    1782px 306px #fff, 290px 519px #fff, 1734px 613px #fff, 1577px 503px #fff,
    1328px 425px #fff, 23px 1964px #fff, 1584px 1249px #fff, 143px 1364px #fff,
    943px 136px #fff, 1612px 517px #fff, 739px 1856px #fff, 1427px 1562px #fff,
    1554px 1647px #fff, 251px 1321px #fff, 193px 278px #fff, 790px 1694px #fff,
    1790px 571px #fff, 493px 1830px #fff, 1220px 1971px #fff, 510px 1376px #fff,
    90px 545px #fff, 510px 100px #fff;
  animation: animStar 50s linear infinite;
}
#stars:after {
  content: " ";
  position: absolute;
  top: 90vh;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1561px 496px #fff, 130px 172px #fff, 301px 1183px #fff,
    1515px 1414px #fff, 391px 1381px #fff, 1624px 40px #fff, 265px 317px #fff,
    866px 232px #fff, 1476px 355px #fff, 1893px 147px #fff, 1741px 908px #fff,
    1626px 1170px #fff, 1301px 821px #fff, 503px 137px #fff, 946px 1322px #fff,
    1725px 292px #fff, 1453px 1120px #fff, 147px 1359px #fff, 1082px 886px #fff,
    1906px 1023px #fff, 229px 181px #fff, 812px 1304px #fff, 454px 582px #fff,
    1677px 1594px #fff, 1755px 307px #fff, 1125px 177px #fff, 435px 1278px #fff,
    1816px 726px #fff, 416px 1963px #fff, 775px 1209px #fff, 1571px 651px #fff,
    1530px 1122px #fff, 1452px 1387px #fff, 554px 168px #fff, 641px 254px #fff,
    526px 778px #fff, 1015px 39px #fff, 530px 1840px #fff, 552px 325px #fff,
    28px 399px #fff, 768px 1008px #fff, 368px 1178px #fff, 596px 145px #fff,
    1964px 1201px #fff, 657px 536px #fff, 97px 530px #fff, 1542px 944px #fff,
    1074px 1855px #fff, 1746px 1534px #fff, 867px 731px #fff, 1659px 501px #fff,
    1220px 1813px #fff, 116px 1406px #fff, 1017px 1972px #fff, 438px 851px #fff,
    322px 98px #fff, 823px 1425px #fff, 1885px 586px #fff, 650px 908px #fff,
    1041px 333px #fff, 1256px 106px #fff, 1381px 1581px #fff, 1555px 1261px #fff,
    899px 1344px #fff, 1605px 5px #fff, 484px 359px #fff, 1733px 1129px #fff,
    151px 135px #fff, 709px 1709px #fff, 103px 963px #fff, 480px 522px #fff,
    1432px 1913px #fff, 645px 675px #fff, 1351px 931px #fff, 1376px 418px #fff,
    1571px 1341px #fff, 1332px 985px #fff, 189px 179px #fff, 110px 1085px #fff,
    1800px 593px #fff, 390px 672px #fff, 890px 614px #fff, 1745px 662px #fff,
    1479px 427px #fff, 1428px 507px #fff, 598px 1921px #fff, 1813px 366px #fff,
    1986px 1854px #fff, 1054px 153px #fff, 760px 223px #fff, 880px 909px #fff,
    384px 856px #fff, 500px 1475px #fff, 1574px 1959px #fff, 1479px 686px #fff,
    707px 557px #fff, 1618px 568px #fff, 484px 103px #fff, 1793px 1399px #fff,
    1874px 1820px #fff, 1074px 1694px #fff, 467px 341px #fff, 285px 620px #fff,
    1304px 850px #fff, 1321px 805px #fff, 818px 1758px #fff, 1450px 1609px #fff,
    44px 203px #fff, 614px 612px #fff, 288px 1593px #fff, 1944px 200px #fff,
    88px 1180px #fff, 1954px 214px #fff, 1372px 177px #fff, 729px 1824px #fff,
    885px 1996px #fff, 1821px 136px #fff, 426px 553px #fff, 626px 1090px #fff,
    771px 1157px #fff, 399px 1303px #fff, 1796px 932px #fff, 732px 151px #fff,
    1794px 1811px #fff, 466px 1585px #fff, 133px 1770px #fff, 856px 844px #fff,
    244px 1841px #fff, 474px 293px #fff, 1051px 1348px #fff, 787px 1775px #fff,
    318px 1506px #fff, 755px 1383px #fff, 123px 935px #fff, 752px 1737px #fff,
    1638px 957px #fff, 848px 1876px #fff, 1524px 768px #fff, 1267px 115px #fff,
    1416px 1845px #fff, 951px 1536px #fff, 1665px 1912px #fff, 1194px 502px #fff,
    684px 1365px #fff, 1902px 1145px #fff, 148px 1701px #fff, 904px 1709px #fff,
    445px 498px #fff, 1834px 627px #fff, 1941px 1975px #fff, 416px 666px #fff,
    231px 1695px #fff, 1709px 1032px #fff, 226px 1156px #fff, 196px 488px #fff,
    563px 860px #fff, 129px 1920px #fff, 1464px 1262px #fff, 2px 1887px #fff,
    438px 117px #fff, 1040px 1041px #fff, 892px 1555px #fff, 384px 1826px #fff,
    1289px 1878px #fff, 1924px 1269px #fff, 914px 420px #fff, 952px 836px #fff,
    1419px 1108px #fff, 1789px 704px #fff, 38px 808px #fff, 391px 847px #fff,
    455px 1818px #fff, 231px 174px #fff, 1566px 1315px #fff, 139px 969px #fff,
    733px 345px #fff, 1392px 1599px #fff, 775px 1318px #fff, 624px 762px #fff,
    1147px 1526px #fff, 1533px 1150px #fff, 1529px 1749px #fff,
    1890px 606px #fff, 1876px 603px #fff, 1270px 478px #fff, 1228px 1556px #fff,
    822px 719px #fff, 1424px 1345px #fff, 1578px 1517px #fff, 530px 1662px #fff,
    557px 1989px #fff, 301px 1782px #fff, 1614px 1468px #fff, 1069px 1655px #fff,
    1973px 929px #fff, 1511px 1385px #fff, 1243px 1575px #fff,
    1566px 1675px #fff, 767px 62px #fff, 813px 939px #fff, 1349px 680px #fff,
    1806px 534px #fff, 1543px 1726px #fff, 23px 948px #fff, 791px 1077px #fff,
    161px 1360px #fff, 45px 1941px #fff, 770px 1232px #fff, 753px 122px #fff,
    1727px 1757px #fff, 801px 1092px #fff, 432px 1054px #fff, 1525px 1559px #fff,
    1534px 839px #fff, 416px 1623px #fff, 473px 1417px #fff, 1656px 291px #fff,
    744px 1001px #fff, 1889px 1025px #fff, 1522px 1184px #fff,
    1273px 1195px #fff, 968px 979px #fff, 89px 1500px #fff, 673px 418px #fff,
    1911px 1037px #fff, 243px 19px #fff, 854px 878px #fff, 846px 1037px #fff,
    905px 352px #fff, 360px 351px #fff, 947px 1689px #fff, 1749px 1681px #fff,
    1737px 99px #fff, 119px 905px #fff, 648px 260px #fff, 88px 1051px #fff,
    1108px 618px #fff, 132px 1308px #fff, 1342px 1640px #fff, 433px 152px #fff,
    1923px 945px #fff, 234px 1454px #fff, 785px 473px #fff, 1232px 1574px #fff,
    471px 1053px #fff, 1148px 1881px #fff, 1357px 1498px #fff,
    1886px 1707px #fff, 507px 1028px #fff, 1467px 1923px #fff, 464px 599px #fff,
    1497px 1521px #fff, 786px 1882px #fff, 1472px 1133px #fff, 607px 332px #fff,
    728px 528px #fff, 586px 1941px #fff, 1684px 1913px #fff, 1212px 1716px #fff,
    1363px 1385px #fff, 455px 1165px #fff, 611px 701px #fff, 1878px 518px #fff,
    1290px 1262px #fff, 1724px 1749px #fff, 920px 1760px #fff, 99px 1204px #fff,
    1350px 663px #fff, 159px 575px #fff, 1309px 1013px #fff, 1935px 1269px #fff,
    638px 484px #fff, 537px 1350px #fff, 546px 1515px #fff, 567px 1178px #fff,
    1149px 1436px #fff, 1918px 830px #fff, 1815px 1103px #fff, 616px 1659px #fff,
    383px 484px #fff, 1252px 719px #fff, 1413px 1456px #fff, 654px 1133px #fff,
    704px 651px #fff, 52px 1493px #fff, 1726px 244px #fff, 1218px 1926px #fff,
    1878px 1198px #fff, 384px 1798px #fff, 1992px 875px #fff, 1863px 270px #fff,
    1451px 173px #fff, 1614px 501px #fff, 319px 1732px #fff, 149px 1981px #fff,
    362px 356px #fff, 1833px 980px #fff, 19px 738px #fff, 48px 154px #fff,
    1725px 1666px #fff, 470px 1393px #fff, 702px 1027px #fff, 1099px 1652px #fff,
    691px 1963px #fff, 362px 1538px #fff, 421px 549px #fff, 1054px 313px #fff,
    572px 2000px #fff, 520px 1998px #fff, 1475px 201px #fff, 1994px 1034px #fff,
    1163px 115px #fff, 944px 315px #fff, 453px 302px #fff, 1101px 7px #fff,
    19px 1415px #fff, 391px 1283px #fff, 1127px 1233px #fff, 1474px 58px #fff,
    1629px 1739px #fff, 1617px 1954px #fff, 1448px 856px #fff,
    1511px 1440px #fff, 53px 1395px #fff, 1152px 1522px #fff, 267px 1084px #fff,
    1534px 783px #fff, 49px 503px #fff, 1173px 1099px #fff, 1260px 639px #fff,
    104px 219px #fff, 1621px 1854px #fff, 1813px 1999px #fff, 888px 111px #fff,
    1801px 512px #fff, 54px 1807px #fff, 27px 397px #fff, 796px 477px #fff,
    208px 1894px #fff, 26px 89px #fff, 1782px 1415px #fff,
}

#stars2 {
  position: absolute;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1725px 1714px #fff, 1196px 1015px #fff, 1916px 1324px #fff,
    1916px 1955px #fff, 1941px 1588px #fff, 560px 1149px #fff,
    1003px 1421px #fff, 1227px 1931px #fff, 1340px 100px #fff, 1522px 582px #fff,
    1645px 370px #fff, 96px 449px #fff, 549px 1511px #fff, 1205px 351px #fff,
    1897px 1698px #fff, 1632px 1000px #fff, 1076px 926px #fff, 477px 317px #fff,
    1378px 730px #fff, 1748px 1986px #fff, 1985px 1172px #fff,
    1664px 1008px #fff, 1683px 666px #fff, 285px 1451px #fff, 1796px 1238px #fff,
    175px 1379px #fff, 1279px 303px #fff, 867px 1865px #fff, 1583px 777px #fff,
    1842px 432px #fff, 538px 1689px #fff, 1155px 1116px #fff, 1803px 757px #fff,
    424px 1298px #fff, 297px 949px #fff, 1511px 1566px #fff, 1801px 30px #fff,
    579px 754px #fff, 1888px 1791px #fff, 1309px 1489px #fff, 1118px 748px #fff,
    505px 322px #fff, 1579px 150px #fff, 938px 1467px #fff, 778px 1561px #fff,
    1185px 869px #fff, 91px 1277px #fff, 284px 890px #fff, 1142px 1658px #fff,
    15px 434px #fff, 1019px 1424px #fff, 1244px 1116px #fff, 117px 1715px #fff,
    1135px 1944px #fff, 1356px 1160px #fff, 774px 1574px #fff, 122px 1822px #fff,
    1505px 505px #fff, 29px 695px #fff, 1583px 675px #fff, 1908px 1051px #fff,
    1218px 781px #fff, 139px 748px #fff, 1879px 1777px #fff, 538px 121px #fff,
    932px 762px #fff, 523px 298px #fff, 1039px 442px #fff, 1354px 580px #fff,
    108px 1337px #fff, 679px 1395px #fff, 286px 1594px #fff, 388px 1950px #fff,
    967px 1692px #fff, 234px 1493px #fff, 28px 764px #fff, 95px 328px #fff,
    1749px 664px #fff, 686px 215px #fff, 336px 1324px #fff, 867px 835px #fff,
    309px 2000px #fff, 1357px 467px #fff, 7px 85px #fff, 1637px 1980px #fff,
    1697px 286px #fff, 1658px 1198px #fff, 1572px 1535px #fff, 1411px 214px #fff,
    398px 282px #fff, 78px 1603px #fff, 980px 931px #fff, 713px 266px #fff,
    319px 737px #fff, 471px 1894px #fff, 1577px 527px #fff, 386px 1606px #fff,
    656px 751px #fff, 770px 298px #fff, 1819px 374px #fff, 1889px 1729px #fff,
    127px 774px #fff, 1832px 915px #fff;
  animation: animStar 100s linear infinite;
}
#stars2:after {
  content: " ";
  position: absolute;
  top: 90vh;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1725px 1714px #fff, 1196px 1015px #fff, 1916px 1324px #fff,
    1916px 1955px #fff, 1941px 1588px #fff, 560px 1149px #fff,
    1003px 1421px #fff, 1227px 1931px #fff, 1340px 100px #fff, 1522px 582px #fff,
    1645px 370px #fff, 96px 449px #fff, 549px 1511px #fff, 1205px 351px #fff,
    1897px 1698px #fff, 1632px 1000px #fff, 1076px 926px #fff, 477px 317px #fff,
    1378px 730px #fff, 1748px 1986px #fff, 1985px 1172px #fff,
    1664px 1008px #fff, 1683px 666px #fff, 285px 1451px #fff, 1796px 1238px #fff,
    175px 1379px #fff, 1279px 303px #fff, 867px 1865px #fff, 1583px 777px #fff,
    1842px 432px #fff, 538px 1689px #fff, 1155px 1116px #fff, 1803px 757px #fff,
    424px 1298px #fff, 297px 949px #fff, 1511px 1566px #fff, 1801px 30px #fff,
    579px 754px #fff, 1888px 1791px #fff, 1309px 1489px #fff, 1118px 748px #fff,
    505px 322px #fff, 1579px 150px #fff, 938px 1467px #fff, 778px 1561px #fff,
    1185px 869px #fff, 91px 1277px #fff, 284px 890px #fff, 1142px 1658px #fff,
    15px 434px #fff, 1019px 1424px #fff, 1244px 1116px #fff, 117px 1715px #fff,
    1135px 1944px #fff, 1356px 1160px #fff, 774px 1574px #fff, 122px 1822px #fff,
    1505px 505px #fff, 29px 695px #fff, 1583px 675px #fff, 1908px 1051px #fff,
    1218px 781px #fff, 139px 748px #fff, 1879px 1777px #fff, 538px 121px #fff,
    932px 762px #fff, 523px 298px #fff, 1039px 442px #fff, 1354px 580px #fff,
    108px 1337px #fff, 679px 1395px #fff, 286px 1594px #fff, 388px 1950px #fff,
    967px 1692px #fff, 234px 1493px #fff, 28px 764px #fff, 95px 328px #fff,
    1749px 664px #fff, 686px 215px #fff, 336px 1324px #fff, 867px 835px #fff,
    309px 2000px #fff, 1357px 467px #fff, 7px 85px #fff, 1637px 1980px #fff,
    1697px 286px #fff, 1658px 1198px #fff, 1572px 1535px #fff, 1411px 214px #fff,
    398px 282px #fff, 78px 1603px #fff, 980px 931px #fff, 713px 266px #fff,
    319px 737px #fff, 471px 1894px #fff, 1577px 527px #fff, 386px 1606px #fff,
    656px 751px #fff, 770px 298px #fff, 1819px 374px #fff, 1889px 1729px #fff,
    127px 774px #fff, 1832px 915px #fff, 1626px 741px #fff, 1208px 444px #fff,
    1227px 1916px #fff, 1367px 1730px #fff, 193px 231px #fff, 175px 210px #fff,
    1346px 334px #fff, 943px 12px #fff, 498px 607px #fff, 289px 154px #fff,
    502px 187px #fff, 1914px 1279px #fff, 373px 1499px #fff, 1012px 241px #fff,
    956px 707px #fff, 1350px 934px #fff, 627px 1854px #fff, 1663px 596px #fff,
    1974px 1581px #fff, 1033px 77px #fff, 1743px 1187px #fff, 1640px 441px #fff,
    1816px 1377px #fff, 918px 887px #fff, 1072px 573px #fff, 689px 1728px #fff,
    620px 1684px #fff, 423px 686px #fff, 849px 1173px #fff, 1913px 1252px #fff,
    1700px 819px #fff, 585px 1679px #fff, 1831px 1522px #fff, 1945px 1475px #fff,
    1283px 1233px #fff, 1237px 1205px #fff, 1392px 1907px #fff,
    799px 1869px #fff, 324px 1758px #fff, 1806px 156px #fff, 796px 460px #fff,
    518px 101px #fff, 1252px 435px #fff, 483px 1131px #fff, 818px 1743px #fff,
    1155px 523px #fff, 1939px 1038px #fff, 267px 628px #fff, 868px 835px #fff,
    508px 802px #fff, 1289px 1937px #fff, 804px 903px #fff, 713px 366px #fff,
    1452px 434px #fff, 1900px 967px #fff, 1588px 1074px #fff, 1689px 1904px #fff,
    83px 372px #fff, 1922px 1615px #fff, 1580px 1629px #fff, 506px 237px #fff,
    1018px 547px #fff, 708px 1224px #fff, 385px 761px #fff, 952px 1315px #fff,
    1316px 1175px #fff, 415px 991px #fff, 1011px 610px #fff, 987px 8px #fff,
    1712px 1660px #fff, 539px 1532px #fff, 1088px 1446px #fff, 685px 462px #fff,
    1015px 122px #fff, 1350px 2px #fff, 1064px 1626px #fff, 1784px 1175px #fff,
    646px 1190px #fff, 1994px 1441px #fff, 1876px 1788px #fff, 423px 1592px #fff,
    1588px 815px #fff, 285px 1766px #fff, 582px 1422px #fff, 1426px 787px #fff,
    552px 1397px #fff, 1647px 1399px #fff, 1713px 836px #fff, 1597px 1648px #fff,
    533px 873px #fff, 1895px 216px #fff, 687px 193px #fff, 308px 297px #fff,
    1117px 1361px #fff, 475px 1292px #fff, 773px 46px #fff, 1711px 1965px #fff;
}

#stars3 {
  position: absolute;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 735px 590px #fff, 905px 1926px #fff, 491px 1631px #fff,
    879px 798px #fff, 377px 64px #fff, 1160px 347px #fff, 732px 425px #fff,
    420px 570px #fff, 1144px 408px #fff, 472px 627px #fff, 1842px 57px #fff,
    455px 949px #fff, 1280px 195px #fff, 297px 1937px #fff, 1056px 1414px #fff,
    225px 1857px #fff, 67px 234px #fff, 1239px 676px #fff, 1783px 578px #fff,
    787px 415px #fff, 221px 692px #fff, 966px 316px #fff, 1573px 279px #fff,
    1339px 1091px #fff, 1280px 537px #fff, 858px 1523px #fff, 1563px 1025px #fff,
    1757px 1265px #fff, 1395px 1233px #fff, 1149px 543px #fff, 380px 1201px #fff,
    1533px 1528px #fff, 1812px 1224px #fff, 1270px 1082px #fff,
    288px 1668px #fff, 250px 1412px #fff, 1345px 1594px #fff, 1752px 1384px #fff,
    1422px 375px #fff, 1700px 500px #fff, 1459px 547px #fff, 1877px 889px #fff,
    1271px 1475px #fff, 723px 508px #fff, 1598px 434px #fff, 457px 1879px #fff,
    1489px 179px #fff, 1693px 1750px #fff, 1692px 795px #fff, 1650px 667px #fff,
    255px 347px #fff, 497px 686px #fff, 1144px 1813px #fff, 1px 1311px #fff,
    1329px 231px #fff, 180px 1715px #fff, 615px 1906px #fff, 86px 300px #fff,
    1926px 80px #fff, 1690px 422px #fff, 906px 1550px #fff, 267px 1578px #fff,
    339px 577px #fff, 804px 1391px #fff, 1344px 520px #fff, 445px 1805px #fff,
    1494px 11px #fff, 50px 931px #fff, 431px 1796px #fff, 918px 1340px #fff,
    1719px 637px #fff, 779px 294px #fff, 540px 1224px #fff, 708px 1011px #fff,
    1456px 485px #fff, 674px 1608px #fff, 246px 148px #fff, 1766px 229px #fff,
    679px 1299px #fff, 992px 1004px #fff, 1840px 167px #fff, 679px 638px #fff,
    1532px 106px #fff, 447px 1910px #fff, 602px 967px #fff, 489px 78px #fff,
    555px 1496px #fff, 207px 1403px #fff, 183px 1308px #fff, 528px 1313px #fff,
    1420px 1636px #fff, 551px 774px #fff, 162px 1318px #fff, 1207px 233px #fff,
    1115px 295px #fff, 612px 184px #fff, 466px 83px #fff, 1525px 1017px #fff,
    1100px 1508px #fff, 264px 840px #fff;
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: " ";
  position: absolute;
  top: 90vh;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 735px 590px #fff, 905px 1926px #fff, 491px 1631px #fff,
    879px 798px #fff, 377px 64px #fff, 1160px 347px #fff, 732px 425px #fff,
    420px 570px #fff, 1144px 408px #fff, 472px 627px #fff, 1842px 57px #fff,
    455px 949px #fff, 1280px 195px #fff, 297px 1937px #fff, 1056px 1414px #fff,
    225px 1857px #fff, 67px 234px #fff, 1239px 676px #fff, 1783px 578px #fff,
    787px 415px #fff, 221px 692px #fff, 966px 316px #fff, 1573px 279px #fff,
    1339px 1091px #fff, 1280px 537px #fff, 858px 1523px #fff, 1563px 1025px #fff,
    1757px 1265px #fff, 1395px 1233px #fff, 1149px 543px #fff, 380px 1201px #fff,
    1533px 1528px #fff, 1812px 1224px #fff, 1270px 1082px #fff,
    288px 1668px #fff, 250px 1412px #fff, 1345px 1594px #fff, 1752px 1384px #fff,
    1422px 375px #fff, 1700px 500px #fff, 1459px 547px #fff, 1877px 889px #fff,
    1271px 1475px #fff, 723px 508px #fff, 1598px 434px #fff, 457px 1879px #fff,
    1489px 179px #fff, 1693px 1750px #fff, 1692px 795px #fff, 1650px 667px #fff,
    255px 347px #fff, 497px 686px #fff, 1144px 1813px #fff, 1px 1311px #fff,
    1329px 231px #fff, 180px 1715px #fff, 615px 1906px #fff, 86px 300px #fff,
    1926px 80px #fff, 1690px 422px #fff, 906px 1550px #fff, 267px 1578px #fff,
    339px 577px #fff, 804px 1391px #fff, 1344px 520px #fff, 445px 1805px #fff,
    1494px 11px #fff, 50px 931px #fff, 431px 1796px #fff, 918px 1340px #fff,
    1719px 637px #fff, 779px 294px #fff, 540px 1224px #fff, 708px 1011px #fff,
    1456px 485px #fff, 674px 1608px #fff, 246px 148px #fff, 1766px 229px #fff,
    679px 1299px #fff, 992px 1004px #fff, 1840px 167px #fff, 679px 638px #fff,
    1532px 106px #fff, 447px 1910px #fff, 602px 967px #fff, 489px 78px #fff,
    555px 1496px #fff, 207px 1403px #fff, 183px 1308px #fff, 528px 1313px #fff,
    1420px 1636px #fff, 551px 774px #fff, 162px 1318px #fff, 1207px 233px #fff,
    1115px 295px #fff, 612px 184px #fff, 466px 83px #fff, 1525px 1017px #fff,
    1100px 1508px #fff, 264px 840px #fff;
}

@keyframes animStar {
  from {
    transform: translateY(-200vh);
  }
  to {
    transform: translateY(-100vh);
  }
}
